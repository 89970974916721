import revive_payload_client_4sVQNw7RlN from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/.nuxt/sentry-client-config.mjs";
import plugin_t2GMTTFnMT from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_eTVJQYlCmx from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import gsap_qE0fizvEy0 from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/plugins/gsap.ts";
import apollo_1ILFkzpYSr from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/plugins/apollo.ts";
import gtm_client_OzmBuHLRIb from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/plugins/gtm.client.ts";
import gtm_tracking_dLptnOZwgx from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/plugins/gtm-tracking.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_t2GMTTFnMT,
  plugin_eTVJQYlCmx,
  gsap_qE0fizvEy0,
  apollo_1ILFkzpYSr,
  gtm_client_OzmBuHLRIb,
  gtm_tracking_dLptnOZwgx
]