export default defineNuxtPlugin((nuxtApp) => {
  /*   nuxtApp.vueApp.directive('track-click', {
    mounted(el, binding) {
      el.addEventListener('click', () => {
        // You can customize what you send to GTM
        const dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'customClickEvent',
          'elementId': el.id,
          'additionalData': binding.value
        });
      });
    }
  }); */
});
